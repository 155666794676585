<template>
  <div id="app" :style="{ 'width': computedWidth }">
    <div class="gradient-bg">
      <div class="blur-1"></div>
      <div class="blur-2"></div>
      <div class="blur-3"></div>
    </div>
    <div v-if="$root.isLoading" class="loading">
      <div class="loading-box">
        <div class="loading-effect">
          <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>

        </div>

      </div>


    </div>
    <transition>
      <router-view></router-view>
    </transition>
    <b-modal id="modal-message" centered hide-footer hide-header>
      <div class="text-close" @click="$bvModal.hide('modal-message')">
        <i class="fa-solid fa-xmark"></i>
      </div>
      <div class="modal-img mx-auto" style="height: 80px; width: 80px;">
        <img v-if="type=='danger'" src="./assets/images/login/error.png" alt="" width="100%">
        <img v-else src="./assets/images/login/success.png" alt="" width="100%">
      </div>
      <h5 class="my-3">
        {{ $parent.msg }}
      </h5>
      <b-button variant="primary" class="" block @click="$bvModal.hide('modal-message')">
        {{ $t('好的') }}
      </b-button>
    </b-modal>


    <b-modal id="modal-src-password" centered hide-footer hide-header style="border-radius: 12px;">
      <!-- <div class="text-close" @click="$bvModal.hide('modal-src-password')">
        <i class="fa-solid fa-xmark"></i>
      </div> -->
      <h5 class="my-3">
        {{ $parent.msg }}
      </h5>
      <h5 class="my-3" style="background-color: black; padding: 20px; border-radius: 12px;">
        <h5 class="label-form-src">
            {{ $t("sec_password") }}
        </h5>
        <b-col cols="12 p-0">
          <input type="password" class="form-control label-input m-0" style="border: 0px; padding: 12px 66px; text-align: center;" v-model="srcPassword"  required />
        </b-col>
      </h5>
      <button :variant="type" class="btn btn-1 btn-secondary w-100" block @click="confirmPassword">
        {{ $t('confirm') }}
      </button>

    </b-modal>

    <b-modal id="modal-edit-profile" centered hide-footer hide-header style="border-radius: 12px;">
      <!-- <div class="text-close" @click="$bvModal.hide('modal-src-password')">
        <i class="fa-solid fa-xmark"></i>
      </div> -->
      <h5 class="mb-2">
        {{ $t('congratulations') }}
      </h5>
      <div class="w-100" style="padding: 0px 65px; color: #B8B8B8;">
        <p class="mb-2 text-12">{{ $t('success_update_your_profile') }}</p>
      </div>
      <div class="modal-img mx-auto" style="width: 70%;">
        <img src="./assets/images/ultbot_onboarding/profile.png" alt="" width="100%">
      </div>
      <button :variant="type" class="btn btn-1 btn-secondary w-100" block @click="$bvModal.hide('modal-edit-profile')">
        {{ $t('done') }}
      </button>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'AIBOT',
  data() {
    return {
      success: require('./assets/images/login/success.png'),
      danger: require('./assets/images/login/error.png'),
      screenWidth: 0,
      srcPassword: '',
    }
  },
  watch: {
    '$parent.msg': 'checkType'
  },
  computed: {
    type(){
      return this.$parent.type;
    },
    computedWidth: function () {
      return this.screenWidth + 'px';
    }
  },
  components: {
  }, methods: {

    confirmPassword() {
      this.$bus.$emit('passwordConfirmed', this.srcPassword);
      this.srcPassword = "";
      this.$bvModal.hide('modal-src-password');
    },

    viewCart() {
      this.$bvModal.hide('modal-message');
      this.$router.push('/web/product/cartList');
    },
    viewRestock() {
      this.$bvModal.hide('modal-message');
      this.$router.push('/web/reseller/prompt');
    },
    checkType() {
      console.log(this.$parent.type);
      switch (this.$parent.type) {
        case 'reminder':
          this.img = this.reminder;
          break;
        case 'cart':
          this.img = this.success;
          break;
        case 'success':
          this.img = this.success;
          break;
        case 'save':
          this.img = this.save;
          break;
        case 'sent':
          this.img = this.sent;
          break;
        case 'invest':
          this.img = this.invest;
          break;
        case 'surrender':
          this.img = this.surrender;
          break;
        case 'danger':
          this.img = this.danger;
          break;
        case 'coming':
          this.img = this.coming;
          break;

        default:
          this.img = this.success;
          break;
      }
    }
  },
  mounted() {
    this.screenWidth = screen.width;

  }
}
</script>

<style>
.bottom-sheet__content {
    overflow-y: hidden !important;
}
.bottom-sheet__pan{
  background-color: #202022;
  border-radius: 10px 10px 0px 0px;
}

.bottom-sheet__bar{
  background-color: #DCDCDC !important;
}

#app {
  font-family: 'Manrope', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.label-form-src {
    font-family: 'Manrope', sans-serif;
    text-align: center;
    font-size: 10px !important;
    font-weight: 500;
    line-height: 16px;
    color: #848484;
}

</style>
